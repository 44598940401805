import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.css";
import LoginModal from "../Login/LoginModal";
import Dropdown from "./Dropdown";
import userStore from "../../stores/userStore";
import AuthUser from "../AuthUser/AuthUser";
import axios from "axios";
import { getuserData } from "../../utils";

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [authType, setAuthType] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const { token: userToken, subscriptionDetails } =
    userStore((state) => state.user) ?? {};
  const user = userStore((state) => state.user);
  const completeUserData = userStore((state) => state.user);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const auth = searchParams.get("auth");
  const setUser = userStore((state) => state.setUser);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const openLogin = () => {
    setAuthType("login");
  };

  const closeLogin = () => {
    setAuthType("");
  };

  useEffect(() => {
    if (auth) {
      openLogin();
    }
  }, [auth]);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pathName = window.location.pathname;

  const menuItems = [
    { path: "/", label: "Home", show: true },
    { path: "/Membership", label: "Memberships", show: true },
    { path: "/Products", label: "Products", show: true },
    { path: "/Programs", label: "Courses", show: true },
    { path: "/Download", label: "Download", show: true },
  ];

  return (
    <div className="header-container">
      <AppBar className={`appbar ${scrolled ? "scrolled" : ""}`} style={{background:"#00000000"}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "black", // Set background color to black
              color: "white", // Optional: Set text color to white for better contrast
            },
          }}>
            <List>
              <ListItem
                component={NavLink}
                to="/"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                component={NavLink}
                to="/Membership"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Memberships" />
              </ListItem>
              <ListItem
                component={NavLink}
                to="/Products"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Products" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/Programs"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Programs" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/Download"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Download" />
              </ListItem>
            </List>
          </Drawer>
          <NavLink to="/" className="nav-link">
            <img
              src="/Assets/logo.png"
              alt="logo"
              className="logo"
            />
          </NavLink>
          <ul className="menu" style={{ display: { xs: "none", md: "flex" } }}>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.show && (
                  <li key={index}>
                    <Button
                      color="inherit"
                      component={NavLink}
                      to={item.path}
                      style={{
                        padding: "0",
                        textTransform: "capitalize",
                        color: pathName === item.path ? "#3BFBEC" : "inherit",
                      }}
                    >
                      {item.label}
                    </Button>
                    {pathName === item.path && (
                      <Divider
                        style={{ width: "100%" }}
                        // style={{ width: "100%", backgroundColor: "#3BFBEC" }}
                      />
                    )}
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
          {user ? (
            <div className="userIcon">
              <Dropdown />
            </div>
          ) : (
            <Button
              color="inherit"
              onClick={openLogin}
              className="btn login-btn"
              sx={{
                width: "8vw",
                height: "45px",
                borderRadius: "30px",
                margin: "0 50px 0 auto",
                border: "1px solid #4efcff",
                minWidth: "100px",
                fontFamily: "Geom",
                color: "white", // Initial text color
                transition: "all 0.4s ease", // Smooth transition for hover effects
                "&:hover": {
                  backgroundColor: "#019191", // Invert background to white on hover
                  color: "white", // Change text color to original background color
                  borderColor: "white", // Change border color on hover for consistency
                },
              }}
            >
              Login
            </Button>
          )}
          {/* <Button
            color="inherit"
            onClick={openLogin}
            className="btn"
            sx={{
              width: "8vw",
              height: "45px",
              borderRadius: "30px",
              background: "#52c4cf",
              margin: "0 50px 0 auto",
              border: "2px solid white",
            }}
          >
            Login
          </Button> */}
        </Toolbar>
      </AppBar>
      <AuthUser authType={authType} setAuthType={setAuthType} />
      {/* <LoginModal
        isOpen={openLoginModal}
        onClose={closeLogin}
        authType={authType}
        onForgotPassword={() => {
          console.log("forgot Password");
        }}
      /> */}
    </div>
  );
};

export default Header;
