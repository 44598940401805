import React from "react";
import "./ProductCarousel.css";
import MemberButton from "../MemberButton/MemberButton";

const ProductCarousel = () => {
  return (
      <div className="container-fluid">
      <div className="row d-flex align-items-center">
      <div className="col-12">
    <div className="featuresGrid">

  
      <div className="textbox product_text">
        <h1 className="title-txt" style={{ color: "white" }}>
          EXPERIENCE{" "}
          <span style={{ color: "rgb(78, 252, 255)" }}>INNOVATION</span>
        </h1>
        <h5 style={{ fontSize: "20px", color: "white", marginTop: "10px" }}>
          Experience the power of innovation with our groundbreaking mobile and
          desktop apps
        </h5>
        <p style={{ fontSize: "16px", color: "whitesmoke", marginTop: "10px" }}>
          Designed to revolutionize the way you trade and learn, our apps
          deliver cutting-edge features and advanced tools that redefine the
          trading landscape.
        </p>
        <div className="btn-container left">
          <MemberButton />
        </div>
      </div>
    
      
            <div className="phone product_video">
        <video
          playsInline
          src="Assets/mobile-slide.mp4"
          muted
          poster="Assets/Mask Group 60.png"
          style={{
            height: "50vh",
            borderRadius: "12px",
          }}
          controls
          autoPlay
          loop
        >
          <div className="bottom">
            <input
              type="range"
              name="range"
              id="progressBar"
              value="0"
              min="0"
              max="100"
            />
            <div className="icons">
              <i className="fa-solid fa-3x fa-play" id="masterPlay"></i>
            </div>
          </div>
        </video>
      </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default ProductCarousel;
