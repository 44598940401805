import React from "react";
import "./Carousel.css";
import MemberButton from "../MemberButton/MemberButton";

const Carousel = () => {
  return (
    <section className="video-section home_section">
    <div className="container-fluid">
    <div className="row">
   <div className="col-12 px-0">

<div className="video-container">
      <video
        src="https://ail-do.sgp1.cdn.digitaloceanspaces.com/torofx/torofx_bg.mp4"
        autoPlay
        loop
        muted
        playsInline width="1500" height="1500"
      ></video>
      </div>
      <div className="textBox1 hero_content">
        <h3
          style={{
            fontWeight: "normal",
            fontSize: "30px",
            marginBottom: "10px",
          }}
        >
          Innovate Your Trading
        </h3>
        <h1 className="title-txt">
          The <span1>Complete Education </span1>Package
        </h1>
        <p className="text-base">
          Discover the power of knowledge with our cutting-edge education
          platform. Our custom-built mobile and desktop apps are designed to
          equip you with the skills and strategies needed to thrive in the
          exciting world of Forex and Crypto.
          <br />
          Unlock a world of opportunity with our comprehensive educational
          content, meticulously curated for growth.
        </p>
        <div className="membership-buttons">
          <MemberButton />
          {/* <button href="#" className="btn-2">
            Try for FREE!
          </button> */}
        </div>
      </div>
      </div>
      </div>
      </div>
    </section>
  );
};

export default Carousel;
