// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featuresGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
}
.textbox,
.phone {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  /* margin-left: 25px; */

}
.phoneGrid {
  width: 10vw;
  height: 40vh;
  border-radius: 20px;
}

.btn-1 {
  cursor: pointer;
  border-radius: 12px;
  color: white;
  transition: background-color 0.6s ease;
  width: 15vw;
  height: 5vh;
  background: #52c4cf;
  color: white;
  margin-right: 10px;
  margin-top: 10px;
}
.bottom {
  position: sticky;
  height: 100px;
  background-color: black;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: aliceblue;
}
.icons i {
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ProductCarousel/ProductCarousel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;AACA;;EAEE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;;AAEzB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,sCAAsC;EACtC,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,cAAc;EACd,eAAe;AACjB","sourcesContent":[".featuresGrid {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 90vh;\n}\n.textbox,\n.phone {\n  width: 50%;\n  padding: 20px;\n  box-sizing: border-box;\n  /* margin-left: 25px; */\n\n}\n.phoneGrid {\n  width: 10vw;\n  height: 40vh;\n  border-radius: 20px;\n}\n\n.btn-1 {\n  cursor: pointer;\n  border-radius: 12px;\n  color: white;\n  transition: background-color 0.6s ease;\n  width: 15vw;\n  height: 5vh;\n  background: #52c4cf;\n  color: white;\n  margin-right: 10px;\n  margin-top: 10px;\n}\n.bottom {\n  position: sticky;\n  height: 100px;\n  background-color: black;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  color: aliceblue;\n}\n.icons i {\n  padding: 10px;\n  margin: 0 10px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
