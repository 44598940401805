import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;
  font-family: "Roboto", sans-serif;
`;

const SuccessIcon = styled.div`
  font-size: 6rem;
  margin-bottom: 2rem;
  color: #fff;
`;

const SuccessMessage = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
`;

const SuccessDetails = styled.p`
  font-size: 1.2rem;
  text-align: center;
  max-width: 500px;
  margin-bottom: 2rem;
`;

const BackButton = styled.button`
  background-color: #114654;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s ease;
  &:hover {
    background-color: #227287;
    color: #fff;
  }
`;

const CountdownMessage = styled.p`
  font-size: 1rem;
  margin-top: 1rem;
`;

const ThankYouPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/Download");
    }, 10000);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
    };
  }, [navigate]);

  return (
    <Container>
      <SuccessIcon>✅</SuccessIcon>
      <SuccessMessage>Thank You for Your Payment!</SuccessMessage>
      <SuccessDetails>
        Your payment was successful. You now have access to our comprehensive
        trading education resources. Start your journey to become a skilled
        trader today!
      </SuccessDetails>
      <BackButton onClick={() => navigate("/Download")}>
        Go to Download Page
      </BackButton>
      <CountdownMessage>
        You will be automatically redirected to the download app page in{" "}
        {countdown} seconds.
      </CountdownMessage>
    </Container>
  );
};

export default ThankYouPage;
