import React from "react";
import "./ProductFeatures.css";
import MemberButton from "../MemberButton/MemberButton";
const ProductFeatures = () => {
  return (
    <>
      <div className="container-fluid">
      <div className="row d-flex align-items-center">
      <div className="col-12">
        <div className="textBox1">
          <h1 className="title-txt" style={{ width: "100%" }}>
            The Power in Your Pocket
          </h1>
          <p
            className="text-width-lg"
            style={{
              marginTop: "10px",
              fontSize: "18px",
              color: "#FFFFFF",
              opacity: "0.8",
              marginBottom: "10px",
            }}
          >
            Unlock the ultimate trading education experience with our
            all-encompassing mobile app. No more navigating through multiple
            sources or platforms. Our app consolidates educational content,
            market analysis, community webinars, and more, delivering a
            streamlined and immersive learning experience directly to your
            device.
          </p>
          <div className="btn-container">
            <MemberButton />
          </div>
        </div>
      </div>
      </div>
      </div>

      <div className="container-fluid">
      <div className="row d-flex align-items-center">
      <div className="col-12">
      <div className="featuresGrid hidden">
        <div className="phone1" style={{ width: "100%" }}>
          <img src="/Assets/3-mobile-in-hand1.png" alt="Mobile in hand" className="product_imgss"/>
        </div>

        {/* <div className="textbox"></div> */}
      </div>    
      <img
        className="mobile-img"
        src="/Assets/3-mobile-in-hand1.png"
        alt="Mobile in hand"
      />
        </div>
      </div>
      </div>

      <div class="container-fluid">
      <div class="row d-flex align-items-center">
      <div class="col-12">
<div>

      <div className="featuresGrid stay_loop">
      <div className="col-lg-6">
        <div className="textbox features_text">
          <h1 className="title-txt unleash_head" style={{ color: "white", width: "100%" }}>
            Unleash the Desktop App
          </h1>
          <p
            style={{
              marginTop: "10px",
              fontSize: "18px",
              color: "#FFFFFF",
              opacity: "0.8",
            }}
          >
            Embark on an unparalleled trading education journey with our
            cutting-edge desktop app. Immerse yourself in a world of knowledge,
            complemented with the beauty of a larger screen. Experience a custom
            dashboard with real-time weather, trading time zones, and
            notifications.
          </p>

          <MemberButton />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="phone">
          <img
            className="move-left"
            src="/Assets/featured/Tpr4fx_4.png"
            alt="Desktop app"/>
        </div>
        </div>
        </div>
      </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default ProductFeatures;
