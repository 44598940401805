import React, { useState, useEffect, useRef } from "react";
import "./Dropdown.css";
import userStore from "../../stores/userStore";
import { useNavigate } from "react-router-dom";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = userStore((state) => state.user);
  const clearUser = userStore((state) => state.clearUser);
  const dropdownRef = useRef(null); // Create a ref for the dropdown component
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (option === "Logout") {
      navigate("/");
      clearUser();
    }
    if (option === "profile") {
      navigate("/Profile");
    }
    if (option === "subscriptions") {
      navigate("/#pricing");
    }
    setIsOpen(false); // Close the dropdown after selecting an option
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="dropdown-toggle" onClick={toggleDropdown}>
        <div className="profile-pic">
          <img src={user?.user?.photo ?? "/Assets/default.jpg"} alt="profile" />
        </div>
        <span className="user-greeting">
          Hi,{" "}
          <span className="user-name">
            {user?.user?.name ? user?.user?.name : "user"}
          </span>
        </span>
        {/* <span className="material-icons">expand_more</span> */}
      </div>
      {isOpen && (
        <div className="dropdown-menu open">
          <div
            className="dropdown-item"
            onClick={() => handleOptionClick("profile")}
          >
            Profile
          </div>
          <div
            className="dropdown-item"
            onClick={() => handleOptionClick("subscriptions")}
          >
            Subscriptions
          </div>
          <div
            className="dropdown-item"
            onClick={() => handleOptionClick("Logout")}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
