import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Pricing from "../Pricing/Pricing";
import ProductCarousel from "../ProductCarousel/ProductCarousel";
import ProductFeatures from "../ProductFeatures/ProductFeatures";
import Desktop from "../Desktop/Desktop";
import ChatFAB from "../ChatFAB/ChatFAB";
import Playstore from "../Playstore/Playstore";

const About = () => {
  return (
    <>
      <Header />
      <ProductCarousel />
      <ProductFeatures />
      <Desktop />
      <Pricing />
      {/* <ChatFAB /> */}
      <Playstore />
      <Footer />
    </>
  );
};

export default About;
