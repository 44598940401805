import React from "react";
import "./MembershipCarousel.css";
import MemberButton from "../MemberButton/MemberButton";

const MembershipCarousel = () => {
  return (
    <section className="video-section home_section">
    <div className="container-fluid">
    <div className="row">
   <div className="col-12 px-0">
   <div className="video-container">
      <video playsInline src="Assets/mobile-slide.mp4" autoPlay loop muted width="1500" height="1500"></video>
      </div>
      <div className="col-12">
      <div className="textBox full-width membership_content2">
        <h1 className="title-txt">
          <span1>EFFORTLESS</span1> LEARNING,
          <br /> REVOLUTIONARY <span1>RESULTS.</span1>
        </h1>
        <h3 className="text-base member_head">
          Welcome to Toro FX, your ultimate destination for mastering the
          financial markets. Our platform is designed to empower members with
          seamless education in forex, futures, and market dynamics. Explore a
          world of innovative features crafted to accelerate your journey and
          unlock unprecedented growth potential.
        </h3>
        {/* <p style={{ fontSize: "18px", color: "#687497" }}>
          Discover a world of features designed to accelerate your trading
          journey and maximize your potential.
        </p> */}
        <div className="membership-buttons">
          <MemberButton />
        </div>
        </div>
      </div>
      </div>
      </div>
      </div>
    </section>
  );
};

export default MembershipCarousel;
