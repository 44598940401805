import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import userStore from "../../stores/userStore";
import "./Profile.css"; // Import CSS file for styling
import axios from "axios";
import UserRaniking from "../UserRanking/UserRanking";
import MemberShipDetails from "../MemberShipDetails/MemberShipDetails";
import { Edit, Email, EmailOutlined, Phone } from "@mui/icons-material";
import UpdateProfile from "../UpdateProfile/UpdateProfile";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Footer from "../Footer/Footer";

export default function Profile() {
  const { user, token } = userStore((state) => state.user) ?? {};
  const [showEdit, setShowEdit] = useState(false);
  const navigate = useNavigate();
  const [rankIcon, setRankIcon] = useState();

  const completedChaptersCount = user?.chapterCompleted.length;
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
      <div className="row d-flex align-items-center">
      <div className="col-12">
      <div className="profile-page-container">
        <div className="profile-container">

        <div className="col-xxl-7 col-xl-6 col-lg-7 col-md-12 col-12">

          <div className="profile my_dp">
            <div className="profile-picture">
              {" "}
              {rankIcon && (
                <div className="edit">
                  <img className="rank-icon" src={rankIcon} alt="rankIcon" />
                </div>
              )}
              <img
                className="profile-img"
                src={user?.photo ?? "/Assets/default.jpg"}
                alt="Profile"
              />
            </div>
            <div>
              <div className="user-name-div">
                <h3 className="profile-txt" style={{ marginBottom: "10px" }}>
                  {user?.name}
                </h3>
              </div>
              <div>
                <div className="user-details-container">
                  <div className="user-data  profile-txt">
                    <Email />
                    {user?.email}
                  </div>
                  {user.mobile && (
                    <div className="user-data profile-txt">
                      <Phone />
                      {user?.mobile}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-xxl-7 col-xl-6 col-lg-7 col-md-12 col-12">
          <MemberShipDetails />
          </div>
          <div className="edit-profile-btn top-right">
            <Button
              sx={{
                background: "#114654",
                color: "white",
                padding: "5px 10px",
                "box-shadow":
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
              onClick={() => setShowEdit(true)}
            >
              Edit Profile
            </Button>
          </div>
        </div>
        <UserRaniking setRankIcon={setRankIcon} />
        {showEdit && (
          <UpdateProfile onClose={() => setShowEdit(false)} isOpen={showEdit} />
        )}
      </div>
      </div>
      </div>
      </div>
      <Footer />
    </>
  );
}
