import React from "react";
import { Card, CardContent, Typography, CardMedia } from "@mui/material";
import "./Ranking.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Ranking = () => {
  const ranks = [
    { title: "Bronze", image: "/Assets/rank/Bronze.png", color: "#C77B30" },
    { title: "Silver", image: "/Assets/rank/Silver.png", color: "silver" },
    { title: "Gold", image: "/Assets/rank/Gold.png", color: "gold" },
    { title: "Platinum", image: "/Assets/rank/Platinum.png", color: "#DEDDDB" },
    {
      title: "Elite",
      image: "/Assets/rank/Elite.png",
      color: "#4EFCFF",
    },
  ];

  return (
    <div className="container">
      <div className="textBox1">
        <h1
          className="title-txt"
          style={{
            color: "white",
            textAlign: "center",
            width: "100%",
          }}
        >
          <span style={{ color: "#4efcff" }}>Ranking</span> System
        </h1>
        <p style={{ fontSize: "20px", color: "white", textAlign: "center" }}>
          Collaboratively administrate empowered markets via plug-and-play
          networks.
        </p>
      </div>

      <div className="ranking-card" style={{ marginTop: "80px" }}>
        {ranks.map((rank, index) => (
          <div className="cardWrapper" key={index}>
            <Card
              className="pricingCard"
              style={{
                background: "#85e3fc1f",
                borderRadius: "18px",
                "box-shadow":
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <CardContent>
                <CardMedia
                  component="img"
                  src={rank.image}
                  className="rankCard"
                  sx={{ height: "150px", width: "150px" }}
                />
              </CardContent>
            </Card>
            {index !== ranks.length - 1 && (
              <ArrowRightAltIcon
                className="ranking-card-arrow"
                sx={{ color: "white", fontSize: "70px" }}
              />
            )}
            <Typography
              variant="h6"
              style={{
                color: rank.color,
                marginTop: "10px", // Adds some space between the card and the title
                fontFamily: "Geom",
                textAlign: "center",
              }}
            >
              {rank.title}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ranking;
