import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Download.css";
import { button } from "@mui/material";

export default function Download() {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row d-flex align-items-center">
          <div className="col-12">
            <div
              className="download-container"
              style={
                {
                  // backgroundImage: "url('/Assets/bg (3).png')",
                }
              }
            >
              <div>
                <h1
                  className=""
                  style={{
                    textAlign: "center",
                    color: "white ",
                    marginBottom: "30px",
                    width: "100%",
                    fontSize: 24,
                  }}
                >
                  Download Toro
                  <span
                    style={{
                      color: "rgb(59, 251, 236)", // FX in desired color
                      // textShadow: "0 0 10px rgb(59, 251, 236), 0 0 20px rgb(59, 251, 236)", // Add glowing FX effect
                      fontWeight: "bold", // Emphasize FX
                    }}
                  >
                    FX
                  </span>{" "}
                  App
                </h1>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      color: "white",
                      opacity: "0.8",
                      width: "80%",
                    }}
                  >
                    Unlock the insider secrets of successful trading with our
                    app! Gain access to expert strategies and market insights to
                    master the markets and achieve financial success. Download
                    now and start your journey!
                  </p>
                </div>
              </div>
              <div className="os-container">
                <div className="os-div">
                  <div className="os-img-center">
                    <img src="/Assets/Download for macOS.svg" alt="windows" />
                  </div>
                  <button
                    style={{
                      borderRadius: "25px",
                      border: "1px solid white",
                    }}
                    className="btn-2 downlod_btn"
                  >
                    <h5 style={{ margin: 0 }}> Download</h5>
                    {/* <h6>macOS 10.5+</h6> */}
                  </button>
                </div>
                <div className="os-div">
                  <div className="os-img-center">
                    <img src="/Assets/download for windows.svg" alt="windows" />
                  </div>
                  <button
                    onClick={() => {
                      window.open(
                        "https://ail-do.sgp1.digitaloceanspaces.com/torofx/Installers/ToroFX.exe",
                        "_blank"
                      );
                    }}
                    className="btn-2 downlod_btn"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid white",
                    }}
                  >
                    <h5 style={{ margin: 0 }}> Download</h5>
                    {/* <h6>Windows 10, 11</h6> */}
                  </button>
                </div>
                <div className="os-div">
                  <div className="os-img-center">
                    <img src="/Assets/googleplay.svg" alt="windows" />
                  </div>
                  <div
                    className="os-img-center dwnld_img"
                    style={{ height: "fit-content", marginTop: "-12px" }}
                  >
                    <img src="/Assets/app-store.svg" alt="windows" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
